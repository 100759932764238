<script>
import {exchangeAdminLogin, getOrderDetailByNumber} from "@/api/home";
import simpleKeyboard from '@/components/simpleKeyboard'

export default {
  components: {
    simpleKeyboard
  },
  data() {
    return {
      value: '',
      flag: true,
      list: [],
      visible: false,
      layout: {
        'default': [
          "1 2 3 4",
          "5 6 7 8",
          '{bksp} 9 0 {enter}',
        ],
      },
    }
  },
  methods: {
    async onLogin(card) {
      await exchangeAdminLogin({card})

      this.flag = false
      document.onkeydown = null

      this.visible = true
      this.$nextTick(() => {
        this.$refs?.orderInput?.querySelector('input')?.focus()
      })

    },
    goAdminExchange(t) {
      this.$router.push({path: "/admin/exchange", query: {orderNumber: t.number}});
    },
    async onSearch() {
      const res = await getOrderDetailByNumber({orderNumber: this.value})
      console.log(`getOrderDetailByNumber`, res)

      this.list = res?.data ? [res?.data] : []
      this.visible = false
    },
    onChange2(input) {
      console.log(`onChange2`, input)
      this.value = input
    },
    onKeyPress2(button) {
      console.log(`onKeyPress2`, button)
      if (button === "{enter}") {
        // 如果按确认键的相应操作
        this.onSearch()
      }
      if (button === "{bksp}") {
        // 删除键的相应操作
      }
    },
  },
  async mounted() {
    let str = ''
    document.onkeydown = (e) => {
      const key = window.event.key;
      if (this.flag) {
        if (key === 'Enter') {
          this.onLogin(str)
          str = ''
        } else {
          str = str + key;
        }
      }
      if (key === 'Enter') {
        e.preventDefault()
      }
    }

    // 模拟登录
    // this.onLogin('1063361012')
  }
}
</script>

<template>
  <div class="app-container">
    <div class="app-content">
      <div class="logo">
        <van-image width="1rem" height="1rem" fit="contain" :src="require('@/assets/logo.png')"/>
        <span class="title">供销门市部</span>
        <van-button class="cancel" style="margin-left: auto;" @click="() => $router.back()" size="mini" type="default">
          取消交易
        </van-button>
      </div>
      <p class="tips" v-if="flag">请先刷卡进行登录</p>
      <div class="search-box">
        <van-search ref="orderInput" v-if="!flag" v-model="value" placeholder="请输入搜索关键词" :clearable="false" @search="onSearch" @focus="visible = true"/>
      </div>
      <div class="order-box" v-if="!flag">
        <div class="order-list" v-if="list.length > 0">
          <div class="order-item" v-for="(t, idx) in list" :key="idx" @click="goAdminExchange(t)">
            <span>订单编号：{{ t.number }}</span>
            <span style="margin: 10px 0;">订单日期：{{ t.createTime }}</span>
            <div class="order-goods">
              <span>共计{{ t.shoppingOrderDetailList.length || 0 }}件商品</span>
              <span>{{ t.amount }}元</span>
            </div>
          </div>
        </div>
        <div class="order-tips" v-else>
          暂未查询到相关订单
        </div>
      </div>
    </div>

    <div class="simple-keyboard">
      <simpleKeyboard
        v-show="visible"
        @onChange="onChange2"
        @onKeyPress="onKeyPress2"
        keyboardClass="myBaseClass2"/>
    </div>
  </div>
</template>

<style scoped lang="less">
.app-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
}

.title {
  font-size: 18px;
  padding-left: 14px;
}

.cancel {
  width: 90px;
  height: 30px;
  font-size: 12px;
}

.tips {
  font-size: 16px;
  margin-top: 100px;
}

.search-box {
  width: 100%;
}

.order-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
}

.order-list {
  display: flex;
  flex-direction: column;
  padding: 10px 15px 15px 15px;
}

.order-item {
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  background: #f7f8fa;
  padding: 10px;
  margin-bottom: 20px;
}

.order-goods {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.order-tips {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
}

.simple-keyboard {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
}
</style>
