<template>
  <div class="app-contanier">
    <div class="top">
      <div class="name">{{ store.name }}</div>
      <van-button class="cancel" @click="cancel" size="mini" type="default">取消操作</van-button>
    </div>
    <template v-if="proList.length > 0">
      <div class="pro-list">
        <div class="pro-item" v-for="(item) in proList" :key="item.id">
          <van-image width="1.4rem" height="1.4rem" fit="cover" :src="item.coverUrl"/>
          <div class="item-cont">
            <div class="item-cont-top">
              <div class="name">
                <span style="margin-right: 5px;">{{ item.name }}</span>
              </div>

              <!--              <van-icon name="delete-o" size=".4rem" @click="onDelete(index)"/>-->
            </div>
            <div class="item-cont-btm">
              <div class="spec">规格：{{ item.unit }}</div>
              <van-stepper v-model="item.count" disable-input min="0" :max="item.quantity" @change="numberChange"
                           integer
                           input-width=".8rem"
                           button-size=".5rem"/>
              <div class="price">
                <span class="current">¥{{ item.retailPrice }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="empty" v-else>
      <van-image width="5.4rem" height="3.6rem" fit="cover" :src="require('@/assets/empty.png')"/>
      <p>暂无商品，快去添加商品吧</p>
    </div>

    <template v-if="exchangeList.length > 0">
      <van-divider>
        换货商品如下
      </van-divider>
      <div class="pro-list" style="padding: 0">
        <div class="pro-item" v-for="(item, index) in exchangeList" :key="item.id">
          <van-image width="1.4rem" height="1.4rem" fit="cover" :src="item.coverUrl"/>
          <div class="item-cont">
            <div class="item-cont-top">
              <div class="name">
                <span style="margin-right: 5px;">{{ item.name }}</span>
              </div>
              <van-icon name="delete-o" size=".4rem" @click="onDelete(index)"/>
            </div>
            <div class="item-cont-btm">
              <div class="spec">规格：{{ item.unit }}</div>
              <van-stepper v-model="item.quantity" disable-input min="0" @change="numberChange"
                           integer
                           input-width=".8rem"
                           button-size=".5rem"/>
              <div class="price">
                <span class="current">¥{{ item.retailPrice }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="bottom">
      <div class="bottom-left">
        <div class="total-box">
          <div class="total">
            <span class="title">换货：</span>
            <span class="sign">¥</span>
            <span class="amount">{{ totalPrice }}</span>
            <span class="num">(共{{ cartNum }}件）</span>
          </div>
        </div>
        <div class="total-box">
          <div class="total">
            <span class="title">加购：</span>
            <span class="sign">¥</span>
            <span class="amount">{{ exchangePrice }}</span>
            <span class="num">(共{{ exchangeNum }}件）</span>
          </div>
        </div>
      </div>
      <div class="btn" @click="onPay">
        <van-button :loading="subLoading" loading-text="提交中..." :disabled="exchangeList.length === 0 || exchangePrice - totalPrice < 0"
                    color="linear-gradient(to right, #EA0B12, #D80B11)" block>确认付款
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import {getData, getOrderDetailByNumber} from '@/api/home';
import {Toast} from 'vant';

let music1 = new Audio();
export default {
  data() {
    return {
      store: {},
      barcode: '',
      proList: [],
      exchangeList: [],
      totalPrice: 0,
      cartNum: 0,
      exchangePrice: 0,
      exchangeNum: 0,
      flag: true,
      subLoading: false,
      orderNumber: ''
    }
  },
  methods: {
    sussessMp3() {
      music1.src = require('@/assets/add_product_success.mp3')
      music1.play();
    },
    FailMp3() {
      music1.src = require('@/assets/add_product_fail.mp3')
      music1.play();
    },
    onSearch(barcode) {
      this.flag = false;
      const loading = Toast.loading({
        message: '加载中...',
        duration: 0,
      });
      getData('product', {'barcode': barcode}).then(res => {
        if (res.code === 200) {
          loading.clear()
          let data = res.data;
          if (data && data.id) {
            this.flag = true;
            data.quantity = 1;
            let proList = this.exchangeList;
            let idx = proList.findIndex(ele => ele.barcode === data.barcode);
            if (idx >= 0) {
              const it = proList[idx]
              // 当前数量+1
              data.quantity = it.quantity + 1
              // 基础信息会变更 需覆盖更新赋值
              proList.splice(idx, 1, {...it, ...data})
            } else {
              proList.unshift(data)
            }
            this.exchangeList = proList;
            this.sussessMp3();
            this.computePrice();
          } else {
            this.FailMp3();
            this.flag = true;
            loading.clear()
            Toast({
              message: '暂无该商品',
              type: 'fail',
              duration: 2 * 1000
            })
          }
        }

      }).catch(error => {
        loading.clear();
        this.FailMp3();
        this.flag = true;
      });
    },
    numberChange() {
      this.computePrice()
    },
    onDelete(index) {
      this.exchangeList.splice(index, 1);
      this.computePrice();
    },
    computePrice() {
      this.totalPrice = this.proList.map(t => this.bignumber(t.count || 0).times(t.retailPrice).toNumber() || 0).reduce((a, b) => this.bignumber(a).plus(b), 0) || 0
      this.cartNum = this.proList.map(t => this.bignumber(t.count || 0)).reduce((a, b) => this.bignumber(a).plus(b), 0) || 0

      this.exchangePrice = this.exchangeList.map(t => this.bignumber(t.quantity || 0).times(t.retailPrice).toNumber()).reduce((a, b) => this.bignumber(a).plus(b), 0) || 0
      this.exchangeNum = this.exchangeList.map(t => this.bignumber(t.quantity || 0)).reduce((a, b) => this.bignumber(a).plus(b), 0) || 0
    },
    cancel() {
      this.$router.go(-1);
    },
    onPay() {
      const {totalPrice, exchangePrice, proList, exchangeList, orderNumber} = this
      if (!Number(totalPrice)) {
        Toast({
          message: '请先选择换货商品',
          duration: 2 * 1000
        })
        return
      }

      if (!Number(exchangePrice)) {
        Toast({
          message: '请先选择加购商品',
          duration: 2 * 1000
        })
        return
      }

      if (exchangePrice - totalPrice < 0) {
        Toast({
          message: '加购金额必须大于换货金额',
          duration: 2 * 1000
        })
        return
      }

      if (!this.flag) return;

      const cloneExchangeList = JSON.parse(JSON.stringify(exchangeList))
      cloneExchangeList.forEach(element => {
        element.productId = element.id
      });

      const cloneProList = JSON.parse(JSON.stringify(proList))
      const list = cloneProList.filter(t => t.count)
      list.map(t => {
        t.quantity = -t.count
      })

      const tempList = list.concat(cloneExchangeList)
      this.subLoading = true;
      console.log(`tempList`, tempList)
      getData('exchange', {
        refundCode: orderNumber,
        shoppingOrderDetailList: tempList
      }).then(res => {
        this.$router.push({
          name: "pay",
          params: {
            totalPrice: this.bignumber(exchangePrice).minus(totalPrice),
            orderNumber: res.msg,
            cartNum: exchangeList.length
          }
        })
      }).catch(error => {
        this.subLoading = false;
      });

    },
    async getData() {
      const res = await getOrderDetailByNumber({orderNumber: this.orderNumber})
      console.log(`getOrderDetailByNumber`, res)

      this.proList = res?.data?.shoppingOrderDetailList
      this.proList.map(t => {
        t.count = 0
      })

      this.computePrice()
    }
  },
  async created() {
    this.store = this.$storage.get('store').store;
    this.orderNumber = this.$route.query.orderNumber
    await this.getData()

    let str = '';
    document.onkeydown = (e) => {
      const key = window.event.key;
      if (this.flag) {
        if (key === 'Enter') {
          this.onSearch(str)
          str = ''
        } else {
          str = str + key;
        }
      }
      if (key === 'Enter') {
        e.preventDefault()
      }

    }
  }
}
</script>

<style lang="less" scoped>
.app-contanier {
  padding: 23px 15px 75px;
}

.top {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cancel {
    width: 90px;
    height: 30px;
    font-size: 12px;
  }
}

.pro-list {
  padding-top: 10px;
}

.pro-item {
  padding: 20px 15px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-top: 10px;
  position: relative;

  .half-price-tag {
    position: absolute;
    top: 0;
    left: 0;
  }

  .item-cont {
    flex: 1;
    padding-left: 10px;
  }

  .item-cont-top {
    display: flex;

    // align-items: flex-start;
    .name {
      flex: 1;
      padding-right: 20px;
      line-height: 20px;
    }
  }

  .item-cont-btm {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .spec {
      width: 70px;
    }

    .van-stepper {
      width: 80px;
      text-align: center;
    }

    .price {
      display: flex;
      align-items: center;
      flex: 1;
      color: #D80B11;

      .current {
        margin-left: auto;
      }

      .original {
        font-size: 12px;
        color: #666;
        margin-left: 10px;
        text-decoration: line-through;
      }
    }
  }

  /deep/ .van-stepper__minus {
    background-color: #D1D1D1;
    color: #fff;
  }

  /deep/ .van-stepper__plus {
    background-color: #D80B11;
    color: #fff;
  }

  /deep/ .van-stepper__input {
    background-color: transparent;
  }
}

.empty {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom {
  border-top: 1px solid #D3D3D3;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 75px;
  box-sizing: border-box;
  background-color: #fff;

  .bottom-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 14px;

    .total-box {
      font-weight: bold;
      display: inline-block;
      //display: flex;
      //align-items: center;

      margin-bottom: 5px;

      .total {
        display: inline-block;
        color: #D90B11;

        .title {
          color: #000;
        }

        .sign {
          color: #D90B11;
        }

        .amount {
          font-size: 20px;
          line-height: 29px;
          color: #D90B11;
        }

        .num {
          color: #333;
          margin-left: 10px;
        }
      }
    }

    .discount-box {
      font-size: 12px;
      color: #FF3D00;
    }
  }

  .btn {
    width: 140px;

    /deep/ .van-button {
      height: 44px;
      letter-spacing: 1px;
      font-size: 16px;
    }
  }
}

.btn-tool {
  padding: 0 15px;
  position: fixed;
  width: 100%;
  bottom: 80px;
  left: 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  box-sizing: border-box;

  .tool {
    padding: 5px;
    margin-right: 10px;
    background-color: #000000;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 2px;
  }
}

.barcode-pop {
  padding: 15px 20px;

  .title {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EEEEEE;
  }

  .cont {
    margin: 15px 0;
    width: 100%;
    height: 40px;
    border: 1px solid #E70C12;
    border-radius: 4px;
    overflow: hidden;
  }

  .pop-btn {
    padding-top: 15px;
    border-top: 1px solid #EEEEEE;
    display: flex;
    justify-content: flex-end;

    .btn {
      width: 65px;
      height: 32px;
      font-size: 14px;
      letter-spacing: 1px;
      margin-left: 8px;
    }
  }
}
</style>
